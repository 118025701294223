// See https://www.gatsbyjs.org/docs/add-seo-component/

import React from "react"
import { Helmet } from "react-helmet"
import useSiteMetadata from "../hooks/use-site-metadata"

export default function Metadata(props) {
  const siteMetadata = useSiteMetadata()
  const logoStaticURL = `${siteMetadata.siteUrl}/icon.png`

  return (
    <Helmet>
      <html lang="en" />
      <title>{siteMetadata.title}</title>
      <meta name="description" content={siteMetadata.description} />
      <meta name="author" content={siteMetadata.owner} />
      <meta name="keywords" content={siteMetadata.keywords.join(',')} />
      <meta name="robots" content="index,follow" />
      <meta name="image" content={logoStaticURL} />

      {/* Open Graph metadata used by Facebook. See https://ogp.me/ */}
      <meta property="og:title" content={siteMetadata.title} />
      <meta property="og:description" content={siteMetadata.description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={siteMetadata.siteUrl} />
      <meta property="og:image" content={logoStaticURL} />
      <meta property="og:image:alt" content={siteMetadata.title} />

      {/* Twitter metadata. */}
      {props.twitterUsername && (
        <meta property="twitter:creator" content={siteMetadata.twitterUsername} />
      )}
      <meta property="twitter:title" content={siteMetadata.title} />
      <meta property="twitter:description" content={siteMetadata.description} />
      <meta property="twitter:image" content={logoStaticURL} />
      <meta property="twitter:card" content="summary_large_image" />

      <link rel="stylesheet" href="https://use.fontawesome.com/releases/v6.4.2/css/all.css" />
    </Helmet>
  )
}
