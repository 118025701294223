import React from "react"
import styled, { keyframes } from "styled-components"
import { Breakpoints } from "../util/breakpoints"

const highlightInKeyframes = keyframes`
  from {
    color: var(--textColor);
    background-color: var(--backgroundColor);
  }
  to {
    color: var(--linkColor);
    background-color: var(--themedBackgroundColor);
  }
`

const highlightOutKeyframes = keyframes`
  from {
    color: var(--linkColor);
    background-color: var(--themedBackgroundColor);
  }
  to {
    color: var(--textColor);
    background-color: var(--backgroundColor);
  }
`

const StyledTab = styled.div`
  --borderColor: Gray;

  font-size: medium;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  margin: 10px;
  width: 100%;
  color: black;
  text-decoration: none;
  color: var(--textColor);
  background-color: var(--backgroundColor);
  border: none;
  border-left: 1px solid var(--borderColor);
  &.last {
    border-right: 1px solid var(--borderColor);
  }
  cursor: pointer;

  a {
    &:link, &:visited {
      color: var(--textColor);
      text-decoration: none !important;
    }
    &:hover, &:active {
      color: var(--linkColor);
      text-decoration: none !important;
    }
  }

  @media ${Breakpoints.smallOrLarger} {
    font-size: large;
    letter-spacing: 0.2em;
    margin-left: 30px;
    margin-right: 30px;
    animation: ${highlightOutKeyframes} 0.5s ease-out;
  }

  &.tab-selected {
    font-weight: bold;
    color: var(--textColor);
    background-color: var(--backgroundColor);
  }

  // Needs to be defined after .tab-selected
  // or the active tab will blink out after the focus animation
  &:focus, &:hover {
    outline: none;
    color: var(--linkColor);
    background-color: Ivory;
    @media ${Breakpoints.smallOrLarger} {
      animation: ${highlightInKeyframes} 0.25s ease-out;
    }
  }
` 

export default function Tab(props) {
  const classList = []
  if (props.isActiveTab) {
    classList.push("tab-selected")
  }
  if (props.isLast) {
    classList.push("last")
  }
  return (
    <StyledTab
      className={classList.join(" ")}
      onClick={props.onClick}
      data-qa={props["data-qa"]}
      tabindex={props.tabindex}
    >
      {props.children}
    </StyledTab>
  )
}

Tab.defaultProps = {
  "data-qa": "TabNavigator",
  isLast: false,
}
