/* NavigationProvider
 *
 * For keeping track of the active tab
 *
 * The active tab automatically tracks popstate events,
 * although there's a setActiveTab in case you need to set it manually.
 * (E.g., Gatsby's Link doesn't trigger popstate.)
 *
 * Usage:
 *
 *   import "NavigationProvider" from "[DIR]/navigation-provider"
 *   ...
 *   <NavigationProvider>
 *     <p>Stuff here can use the navigatoin context</p>
 *   </NavigationProvider>
 *
 *   import useNavigation from "../hooks/use-navigation"
 *   const navigation = useNavigation()
 *   const activeTab = navigation.getActiveTab()
 *   const setActiveTab = navigation.setActiveTab("about")
 */

import React, { useState } from "react"
import NavigationContext from "../util/navigation-context"
import useEventListener from "../hooks/use-event-listener"

/******************************************************************************/

export default function NavigationProvider(props) {

  const tabMap = {
    "/": "search",
    "/about/": "about",
  }
  const findActiveTab = () => typeof window === "object" ? tabMap[window.location.pathname] : "search"
  const [activeTab, setActiveTab] = useState(findActiveTab())
  useEventListener("popstate", () => {
    setActiveTab(findActiveTab())
  })

  const navigationInterface = {
    getActiveTab: () => activeTab,
    setActiveTab: setActiveTab,
  }

  return (
    <NavigationContext.Provider value={navigationInterface}>
      {props.children}
    </NavigationContext.Provider>
  )
}
