/* GlobalStyle
 *
 */

import React from "react"
import { createGlobalStyle } from "styled-components"
import { Breakpoints } from "../util/breakpoints"

const GlobalStyle = createGlobalStyle`
  body {
    --backgroundColor: White;
    --errorTextColor: DarkRed;
    --linkColor: DarkBlue;
    --darkLinkColor: DarkBlue;
    --textColor: #505050;
    --lightTextColor: DimGray;
    --accentColor: IndianRed;
    --themedBackgroundColor: Ivory;
  
    font-family: "Garamond", "Times", "Nimbus Roman", serif;
    max-width: 80em;
    margin: auto;
    padding-left: 10px;
    padding-right: 10px;
    color: var(--textColor);
    background-color: var(--backgroundColor);

    @media ${Breakpoints.mediumOrLarger} {
      padding-left: 20vh;
      padding-right: 20vh;
      line-height: 150%;
    }

    h1, h2, h3, h4 {
      font-family: "Garamond", "Times", "Nimbus Roman", serif;
    }
    h2, h3, h4 {
      color: var(--lightTextColor);
    }

    a {
      color: var(--linkColor);
    }

  }
  

`

export default function AppliedGlobalStyle(props) {
  return (
   <React.Fragment>
     <GlobalStyle />
     {props.children}
   </React.Fragment>
  )
}
