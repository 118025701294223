import React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"

import useNavigation from "../hooks/use-navigation"

// For addressing rehydration issue
// https://blog.logrocket.com/fixing-gatsbys-rehydration-issue/
import useIsClient from "../hooks/rehydration"

import Tab from "./tab"

/******************************************************************************/

const StyledTabList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  justify-items: center;
  align-items: center;
  border-collapse: collapse;
`

const CenteredContainer = styled.div`
  display: grid;
  justify-items: center;
`

const VerticallyCenered = styled.span`
  display: inline;
  vertical-align: -0.3rem;
`

/******************************************************************************/

export default function TabList(props) {
  const { isClient, key } = useIsClient()

  const navigation = useNavigation()
  const switchToTab = (tab, path) => () => {
    navigation.setActiveTab(tab)
    navigate(path)
  }

  if ( !isClient ) return null
  return (
    <CenteredContainer key={key}>
      <StyledTabList role="navigation" data-qa={props["data-qa"]}>
        <Tab
          isActiveTab={navigation.getActiveTab() === "about"}
          onClick={switchToTab("about", "/about/")}
          tabindex="0"
        >
          <VerticallyCenered>
            About
          </VerticallyCenered>
        </Tab>
        <Tab
          isActiveTab={navigation.getActiveTab() === "search"}
          onClick={switchToTab("search", "/")}
          tabindex="0"
        >
          <VerticallyCenered>
            Search
          </VerticallyCenered>
        </Tab>
        <Tab isActiveTab={false} isLast={true}>
          <VerticallyCenered>
            <a
              href="http://patreon.com/livingarabic"
              target="_blank"
              rel="noreferrer"
            >Donate</a>
          </VerticallyCenered>
        </Tab>
      </StyledTabList>
    </CenteredContainer>
  )
}

TabList.defaultProps = {
  "data-qa": "TabList",
}
