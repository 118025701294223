import React from "react"
import styled from "styled-components"

/******************************************************************************/

const Buffer = styled.div`
  padding-bottom: 30px;
`

const StyledFooter = styled.footer`
  display: grid;
  grid-template-columns: auto;
  background-color: rgb(231,231,231);
  padding: 10px;
  font-size: small;
  line-height: 100%;

  a {
    color: rgb(35,77,121);
  }
`

/******************************************************************************/

export default function Footer(props) {
  return (
    <>
      <Buffer />
      <StyledFooter id="footer" data-qa={props["data-qa"]}>
        <p>
          &copy; 2023 <a
            href="https://livingarabic.com/"
            target="_blank"
            rel="noreferrer"
          >
            The Living Arabic Project
          </a>. All rights reserved.
          Fontawesome icons are licensed under&nbsp;
          <a href="https://fontawesome.com/license" target="_blank" rel="noreferrer">
            CC-BY 4.0</a>. 
        </p>
      </StyledFooter>
    </>
  )
}

Footer.defaultProps = {
  "data-qa": "Footer",
}
