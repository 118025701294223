/* ProviderSetup
 *
 * Set up providers, global styles, etc.
 */

import React from "react"

import GlobalStyle from "./global-style"
import NavigationProvider from "./navigation-provider"
import Metadata from "./metadata"

export default function ProviderSetup(props) {

  return (
    <>
      <Metadata />
      <NavigationProvider>
        <GlobalStyle>
          {props.children}
        </GlobalStyle>
      </NavigationProvider>
    </>
  )
}
