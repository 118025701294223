import React from "react"
import styled from "styled-components"

import TabList from "../components/tab-list"
import Footer from "../components/footer"
import Title from "../components/title"


/******************************************************************************/


const Description = styled.p`
  font-style: italic;
  text-align: center;
`


/******************************************************************************/


export default function UniversalLayout(props) {

  return (
    <div id="page">
      <Title text="The Tsonga Project" />
      <Description>
        A dictionary and record of the Tsonga language
      </Description>
      <TabList />
      <main>
        {props.children}
      </main>
      <Footer />
    </div>
  )

}
